<template>
  <v-text-field
    :placeholder="placeholder"
    rounded
    filled
    prepend-inner-icon="mdi-magnify"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    placeholder: String
  },
  name: 'TableToolbarSearch',
  data() {
    return {}
  }
}
</script>