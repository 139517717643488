<template>
  <v-toolbar flat color="transparent">
    <v-toolbar-title
      class="secondary--text overline"
      v-text="tableTitle"
    ></v-toolbar-title>
    <v-spacer></v-spacer>
    <table-toolbar-search
      v-if="false"
      placeholder="Search for Document Number"
    ></table-toolbar-search>
  </v-toolbar>
</template>

<script>
import TableToolbarSearch from '@/components/TableToolbarSearch.vue'

export default {
  props: {
    tableTitle: String
  },
  name: 'TableToolbar',
  components: { TableToolbarSearch },
  data() {
    return {}
  }
}
</script>

<style scoped>
</style>
